<template src="./referralreports.html"></template>
<style src="./referralreports.css" scoped></style>

<script>
import Loading from 'vue-loading-overlay'
import applicationConstants from '@/constants/AppConstants.vue'
import reportServices from '@/services/ReportService.vue'
import Modal from '@/components/modal/notification/modal'
import utils from '@/utils/utils'
import moment from 'moment'
import JsonToExcel from 'vue-json-excel'
import Pagination from '@/components/vtable/vtpagination.vue'

export default {
    name: "ReferralReport",
    components: {
        Loading, Modal, JsonToExcel, Pagination
    },
    data () {
        return {
            selectedYear: "",
            selectedMonth: "",
            yearList: [],
            monthList: [],
            referralResponseData: [],
            referralTableDataColumns: ['Referrer', 'Referee', 'RefereeSignupDate', 'TransactionTime'],
            referralTableOptions: {
                headings: {
                    RefereeSignupDate: 'Referee Signup Date'
                }
            },
            enablePaginateData: applicationConstants.enableLazyLoading,
            loaderVisible: false,
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            csvFields: {
                "Referrer": "Referrer",
                "Referee": "Referee",
                "RefereeSignupDate": "RefereeSignupDate",
                "TransactionTime": "TransactionTime"
            }
        }
    },
    methods: {
        async searchReferralData () {
            this.loaderVisible = true
            reportServices.methods.GetReferralSummary({
                "Month": this.selectedMonth.toString() ? this.selectedMonth : null,
                "Year": this.selectedYear ? this.selectedYear : null
            }).then(res=> {
                this.referralResponseData = res.csGetReferralReportResponse.ReferralData?.RptReferralData
                this.referralResponseData = this.referralResponseData ? (Array.isArray(this.referralResponseData) ?  this.referralResponseData : [this.referralResponseData]) : []
                if (this.enablePaginateData) {
                    this.$refs.paginationRef.selectedPage = 1
                } else {
                    this.$refs.vueClientTable.setPage(1)
                }
                this.loaderVisible = false
            })
            .catch(err => {
                this.referralResponseData = []
                this.loaderVisible = false
                this.alertObject = err
                this.$bvModal.show(this.$refs.childModal.id)
            })
        },
        reponseModifier (referralData) {
            if (referralData) {
                referralData.forEach(referral => {
                    Object.keys(referral).map(key => referral[key] = referral[key]?._text ? referral[key]._text : null)
                })
            }
            return referralData ? referralData : []
        },
        clearAll() {
            this.selectedYear = ""
            this.selectedMonth = ""
        },
        exportTableData () {
            if (this.referralResponseData.length > 0) {
                return this.reponseModifier(JSON.parse(JSON.stringify(this.referralResponseData)))
            } 
        },
        getYearsList(range) {
            return Array.from({length: range}, (v, i) => (new Date().getFullYear()) - range + i + 1);
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        }
    },
    computed: {
        referralTableData () {
            let referralData = []
            if (this.referralResponseData && this.referralResponseData.length > 0) {
                referralData = JSON.parse(JSON.stringify(this.referralResponseData))
                if(this.enablePaginateData) {
                    let selectedPage = this.$refs.paginationRef?.selectedPage
                    referralData = referralData.slice((selectedPage - 1) * 10, selectedPage * 10)
                }
                referralData = this.reponseModifier(JSON.parse(JSON.stringify(referralData)))
                const updateTotalRecords = () => {
                    if(this.$refs.paginationRef) {
                        this.$refs.paginationRef.totalRecords = this.referralResponseData?.length ?? 0
                    }
                }
                if (this.enablePaginateData) {
                    updateTotalRecords();
                }
            }
            return referralData
        }
    },
    mounted () {
        this.yearList = ((this.getYearsList(50)).map(year => { return {Text: year, Value: year}})).sort((a,b)=> b.Text - a.Text)
        this.monthList = (moment.months()).map((mon, index) => {return { Text: mon, Value: index+1 }})
    }
}
</script>